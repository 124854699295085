import React, { useEffect, useState } from 'react';
import RenewalService from 'renewal/domain/interface/RenewalService';
import PaymentService from 'payment/domain/interface/PaymentService';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import { useErrorModal } from 'core/context/ErrorModalContext';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteParams } from 'core/routing/Routing';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import useRedirectModal from 'payment/Components/RedirectModal';
import GenericModal from 'core/Components/GenericModal';
import OfferType from 'renewal/domain/model/OfferType';
import NewHardwareOfferTemplate from './NewHardwareOffersTemplate';

const NewHardwareOffer = () => {
  /*
   STATE & HOOKS
  */
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const nav = useNavigate();
  const { initRedirect } = useRedirectModal(0);
  const errorModal = useErrorModal();

  const [currentOffer, setCurrentOffer] = useState<HardwareUpgradeOffer | null>(null);

  const { submit, isSubmitting } = PaymentService.useRedeemHardwareUpgradeOffer();
  /*
    INITIAL FETCH
  */

  const { data: offers, isLoading } = RenewalService.useHardwareUpgradeOffers(uuid);

  useEffect(() => {
    if (offers) {
      const firstOffer = offers.find(
        (offer) => offer.type === OfferType.HARDWARE_EARLY_RENEWAL
        || offer.type === OfferType.HARDWARE_UPGRADE_RENEWAL,
      );
      const offer = firstOffer || offers[0];
      setCurrentOffer(offer);
    }
  }, [offers?.length]);

  if (!offers && isLoading) {
    return <LoadingIndicator />;
  }
  if (!offers) {
    errorModal.setText('Error fetching offer');
    errorModal.setOpen();
    nav('/');
    return null;
  }

  const navigateToShopifyCheckout = (url: string) => {
    initRedirect(url);
  };

  const acceptOnClick = (offer: HardwareUpgradeOffer) => {
    submit(uuid, offer!.type, (data: CheckoutResponse) => {
      if (data.data.checkout_url) {
        navigateToShopifyCheckout(data.data.checkout_url);
      } else {
        errorModal.setText('Could not reconcile offer');
      }
    });
  };

  if (!currentOffer) {
    return null;
  }
  return (
    <main className="container container--default">
      <GenericModal isOpen={isSubmitting}>
        <LoadingIndicator />
        <p className="text-24 text-blue-900 font-bold my-16">Loading </p>
      </GenericModal>
      <NewHardwareOfferTemplate
        offers={offers}
        currentOfferType={currentOffer?.type}
        changeOffer={(offer) => { setCurrentOffer(offer); }}
        acceptOnClick={acceptOnClick}
      />
    </main>
  );
};

export default NewHardwareOffer;
