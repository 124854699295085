// import LoadingButton from 'core/Components/LoadingButton';
import React, { useRef, useState } from 'react';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import OfferType from 'renewal/domain/model/OfferType';
import Carv2Sensors from 'images/Carv2Sensors.png';
import utils from 'core/utils';
import FAQ from './FAQ';

interface OfferCardProps {
  offer: HardwareUpgradeOffer
  onClick: (acceptedOffer: HardwareUpgradeOffer) => void
}

const OfferCardEarlyRenewal = ({ offer, onClick } : OfferCardProps) => (
  <div
    role="button"
    tabIndex={0}
    onClick={() => onClick(offer)}
    onKeyPress={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick(offer);
      }
    }}
    className="w-full text-left px-12"
  >
    <p className="md:text-24 text-20 font-bold text-orange-500 mb-12">FREE UPGRADE</p>
    <p className="text-blue-900 md:text-24 text-20 font-bold mb-12">
      With early pass renewal
    </p>
    <p className="md:max-w-[90%] mb-12">
      Pay for your next renewal today and you&apos;ll be eligible for a free upgrade.
      You’ll keep your current billing cycle, giving you access until <span className="font-bold">{offer.renewalDate}</span>
    </p>
    <div className="py-12 text-18">
      <div className="flex md:flex-row flex-col border-t-2 border-grey-350 pt-12">
        <div className="md:basis-1/2 basis-full">
          <div className="w-300">
            <div className="flex items-center py-4 gap-12 w-full">
              <h3 className="text-grey-500">Your Pass:</h3>
              <p className="font-bold text-blue-900 leading-none grow text-right">{offer.title}</p>
            </div>
            <div className="flex items-center py-4 gap-12 w-full">
              <h3 className="text-grey-500">Renews:</h3>
              <p className="font-bold text-blue-900 leading-none text-right grow">{offer.nextChargeDate}</p>
            </div>
          </div>

        </div>
        <div className="md:basis-1/2 basis-full text-right md:mt-0 mt-24">
          <div className="flex w-full md:justify-end justify-between gap-12 mb-6">
            <p className="text-20"> Pass renewal:</p>
            <p className="text-20 font-bold text-blue-900">{offer.currencySymbol}{offer.price}</p>
          </div>
          <div className="flex w-full md:justify-end justify-between gap-12">
            <p className="text-20"> Carv 2 price:</p>
            <p className="text-20 font-bold text-orange-500">FREE</p>
          </div>
        </div>
      </div>
      <p className="text-grey-500 text-11 text-right">{offer.currency !== 'GBP' ? 'Taxes May Apply' : null}</p>

    </div>

  </div>
);

const OfferCardHardwareOnly = ({ offer, onClick } : OfferCardProps) => {
  const isFreeHardware = offer.discountedPrice === 0;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => onClick(offer)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick(offer);
        }
      }}
      className="w-full text-left px-12"
    >

      <p className="text-blue-900 text-24 font-bold">
        {isFreeHardware ? 'Get' : 'Buy'} Carv 2 Sensors
      </p>
      <div className="flex md:flex-row flex-col">
        <div className="mt-4">
          <p>
            {isFreeHardware ? 'Get' : 'Buy'} your new device today {isFreeHardware ? '.' : ' at an exclusive price for existing members.'}
          </p>
          <p>Does not affect pass.</p>
        </div>
        <div className="basis-1/2 text-right grow">
          <div className="flex w-full md:justify-end justify-between md:mt-0 mt-24 gap-12">
            <p className="text-20"> Carv 2 price:</p>
            <div className="flex gap-12">
              <p className="text-20 font-bold line-through">{offer.currencySymbol}{offer.price}</p>
              <p className="text-20 font-bold text-orange-500">{offer.currencySymbol}{offer.discountedPrice}</p>
            </div>
          </div>
          <p className="text-grey-500 text-11 text-right">{offer.currency !== 'GBP' ? 'Taxes May Apply' : null}</p>
        </div>
      </div>

    </div>
  );
};

const OfferCardUpgrade = ({ offer, onClick } : OfferCardProps) => (
  <div
    role="button"
    tabIndex={0}
    onClick={() => onClick(offer)}
    onKeyPress={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick(offer);
      }
    }}
    className="w-full text-left px-12"
  >
    <p className="md:text-24 text-20 font-bold text-orange-500 mb-12">FREE UPGRADE</p>
    <p className="text-blue-900 md:text-24 text-20 font-bold mb-12">
      Upgrade to a season pass for free device
    </p>
    <p className="md:max-w-[95%] mb-12">
      Start a new season pass today,
      giving you unlimited access from today through to
      <span className="font-bold">{` ${utils.toLongDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))}`}</span>
    </p>
    <div className="py-12 text-18">
      <div className="flex md:flex-row flex-col border-t-2 border-grey-350 pt-12">
        <div className="md:basis-1/2 basis-full">
          <div className="w-300">
            <div className="flex items-center py-4 gap-12 w-full">
              <h3 className="text-grey-500">Current Pass:</h3>
              <p className="font-bold text-blue-900 leading-none grow text-right">{offer.title}</p>
            </div>
            <div className="flex items-center py-4 gap-12 w-full">
              <h3 className="text-grey-500">Renews:</h3>
              <p className="font-bold text-blue-900 leading-none text-right grow">{offer.nextChargeDate}</p>
            </div>
          </div>

        </div>
        <div className="md:basis-1/2 md:pl-32 basis-full text-right md:mt-0 mt-24">
          <div className="flex w-full md:justify-end justify-between gap-12 mb-6">
            <p className="flex text-20 grow"> New pass price:</p>
            <p className="text-20"><span className="line-through">{offer.currencySymbol}{offer.price}</span></p>
            <p className="text-20 font-bold text-blue-900">{offer.currencySymbol}{offer.discountedPrice}<span className="text-grey-400 font-normal text-14">/year</span></p>
          </div>
          <div className="flex w-full md:justify-end justify-between gap-12">
            <p className="flex text-20 grow"> Carv 2 price:</p>
            <p className="text-20 font-bold text-orange-500">FREE</p>
          </div>
        </div>
      </div>
      <p className="text-grey-500 text-11 text-right">{offer.currency !== 'GBP' ? 'Taxes May Apply' : null}</p>

    </div>

  </div>
);

const OfferCard = ({ offer, onClick } : OfferCardProps) => {
  if (offer.type === OfferType.HARDWARE_EARLY_RENEWAL) {
    return <OfferCardEarlyRenewal offer={offer} onClick={onClick} />;
  }
  if (offer.type === OfferType.HARDWARE_ONLY) {
    return <OfferCardHardwareOnly offer={offer} onClick={onClick} />;
  }
  if (offer.type === OfferType.HARDWARE_UPGRADE_RENEWAL) {
    return <OfferCardUpgrade offer={offer} onClick={onClick} />;
  }
  return null;
};

interface OfferTemplateProps {
  offer: HardwareUpgradeOffer,
  currentOfferType: OfferType,
  acceptOnClick: (acceptedOffer: HardwareUpgradeOffer) => void,
  faqOnClick: (offerType: OfferType) => void
}

const NewHardwareOfferTemplate = (
  { offer, currentOfferType, acceptOnClick, faqOnClick }: OfferTemplateProps,
) => (
  <>
    <div className={`relative overflow-hidden shadow-grey-50%-sm w-full rounded-12 border flex flex-col py-24 ${currentOfferType === offer.type ? 'border-blue-400 bg-blue-250' : 'border-grey-500 bg-white'}`}>
      <div className="relative overflow-hidden pt-12 px-24 md:max-w-1080">
        <OfferCard
          offer={offer}
          onClick={acceptOnClick}
        />
      </div>
    </div>
    <button type="button" onClick={() => { faqOnClick(offer.type); }} className="underline mt-12 text-blue-400 font-bold">Learn more about this offer</button>
  </>
);

interface OffersTemplateProps {
  offers: HardwareUpgradeOffer[],
  currentOfferType: OfferType,
  changeOffer: (offer: HardwareUpgradeOffer) => void,
  acceptOnClick: (acceptedOffer: HardwareUpgradeOffer) => void,
}

const NewHardwareOffersTemplate = ({ offers, currentOfferType, changeOffer, acceptOnClick }
: OffersTemplateProps) => {
  const [offerTypeFaq, setOfferTypeFaq] = useState(currentOfferType);
  const faqRef = useRef<HTMLDivElement>(null);

  const onOfferClick = (offer: HardwareUpgradeOffer) => {
    if (offer.type === currentOfferType) {
      acceptOnClick(offer);
    } else {
      changeOffer(offer);
    }
  };

  const faqOnClick = (offerType: OfferType) => {
    setOfferTypeFaq(offerType);
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="max-w-780 mx-auto">
      <div className="flex md:flex-row flex-col md:gap-30 mb-12 md:mb-0 justify-start md:px-50 px-12">

        <img className="md:w-[200px] md:h-200 w-150 h-150 mx-auto md:mx-0" src={Carv2Sensors} alt="tips and training" loading="lazy" />
        <div className="max-w-400 flex flex-col justify-center">
          <p className="text-blue-900 text-30 font-bold">Upgrade to Carv 2</p>
          <p>Our most accurate system to date.</p>
          <p>No inserts, no installation, simply clip and go.</p>
          <a href="https://getcarv.com/whats-new" target="_blank" className="underline text-blue-400 font-bold" rel="noreferrer"> Learn more</a>
        </div>
      </div>
      {/* Show Early renewal first */}
      {offers.sort((a) => {
        if (a.type === OfferType.HARDWARE_ONLY) return 1;
        return -1;
      }).map(
        (offer, index) => (
          <>
            <NewHardwareOfferTemplate
              offer={offer}
              acceptOnClick={onOfferClick}
              currentOfferType={currentOfferType}
              faqOnClick={faqOnClick}
            />
            {index !== offers.length - 1 && <p className="flex justify-center my-24 text-24 font-bold">OR</p>}
          </>
        ),
      )}
      <p className="mt-24 md:text-left w-full text-center">Carv 2 is shipping in November, subject to demand.</p>
      <div ref={faqRef}>
        <FAQ offerType={offerTypeFaq} />
      </div>
    </div>

  );
};

export default NewHardwareOffersTemplate;
