import httpRepo, { AVAILABLE_PLANS_KEY, CANCEL_SUBSCRIPTION_KEY, CHOOSE_NEW_PLAN_KEY, DELAY_PLAN_KEY } from 'renewal/api/RenewalHttpRepo';
import mapper from 'renewal/api/mapper';
import skuRepo from 'renewal/api/SkuRepo';
import { SUBSCRIPTIONS_KEY } from 'core/api/CoreHttpRepo';
import { To } from 'react-router-dom';
import { CancelVariables } from 'renewal/api/dto/Cancel';
import useBaseMutation from 'core/domain/interface/hooks/useBaseMutation';
import ChooseNewPlan from 'renewal/api/dto/ChooseNewPlan';
import DelayPlan from 'renewal/api/dto/DelayPlan';

const variantIdsToExclude = ['43652054352088', '40173849378894', '40169059680334', '44378498826550', '44461684326721'];
const carv3ToExclude = '3.0 pass'; // Hotfix to not show 3.0 plans
const useAvailablePlans = (subscripitonId?: string, membershipId?: string) => {
  const isSubscription = subscripitonId !== undefined;
  const entityId = (subscripitonId || membershipId) as string;
  const { data, ...rest } = httpRepo.useFetchAvailablePlans(entityId, isSubscription);
  const mappedData = data?.variants
    .filter((variant) => !variantIdsToExclude.includes(variant.id))
    .filter((variant) => variant.description.includes(carv3ToExclude) === false)
    .map(mapper.toDomainAvailablePlan);
  return { data: mappedData, ...rest };
};

const useUpsellPlans = (membershipId?: string) => {
  const { data, ...rest } = httpRepo.useFetchUpsellPlans(membershipId);
  const mappedData = data?.variants.map(mapper.toDomainUpsellPlan);
  return { data: mappedData, ...rest };
};

// Skus no longer used as of 10/11/22 but may be useful in future so leaving in
const useSkus = () => {
  const skus = skuRepo.getSkus();
  const mappedSkus = skus.map(mapper.toDomainSku);
  return { data: mappedSkus };
};

const useDailySkus = () => {
  const skus = skuRepo.getDailySkus();
  const mappedSkus = skus.map(mapper.toDomainSku);
  return { data: mappedSkus };
};

const useCancelSubscription = () => {
  // eslint-disable-next-line max-len
  const mutation = httpRepo.useCancelSubscription();

  const baseMutation = useBaseMutation(
    CANCEL_SUBSCRIPTION_KEY,
    mutation,
  );

  const submit = (cancelVariables: CancelVariables, navigateUrl: To) => {
    baseMutation.submit(
      cancelVariables,
      { invalidateQueryKeys: [SUBSCRIPTIONS_KEY], navigateUrl },
    );
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useChooseNewPlan = () => {
  // eslint-disable-next-line max-len
  const mutation = httpRepo.useChooseNewPlan();

  const baseMutation = useBaseMutation(
    CHOOSE_NEW_PLAN_KEY,
    mutation,
  );

  const submit = (newPlanVariables: ChooseNewPlan, navigateUrl: To) => {
    baseMutation.submit(
      newPlanVariables,
      { invalidateQueryKeys: [SUBSCRIPTIONS_KEY, AVAILABLE_PLANS_KEY], navigateUrl },
    );
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useDelayPlan = () => {
  // eslint-disable-next-line max-len
  const mutation = httpRepo.useDelayPlan();

  const baseMutation = useBaseMutation(
    DELAY_PLAN_KEY,
    mutation,
  );

  const submit = (delayPlan: DelayPlan, navigateUrl: To) => {
    baseMutation.submit(
      delayPlan,
      { invalidateQueryKeys: [SUBSCRIPTIONS_KEY], navigateUrl },
    );
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useEndOfSeasonOffer = () => {
  const { data, ...rest } = httpRepo.useFetchEndOfSeasonOffer();
  if (!data) return { data: undefined, ...rest };
  return { data: mapper.toDomainOffer(data?.offer), ...rest };
};

const useHardwareUpgradeOffers = (offerUuid: string) => {
  const { data, ...rest } = httpRepo.useFetchHardwareUpgradeOffers(offerUuid);
  if (!data) return { data: undefined, ...rest };
  return {
    data: data.offer.offers.map((offer) => mapper.toDomainHardwareUpgradeOffer(offer, data.offer)),
    ...rest,
  };
};

const service = {
  useAvailablePlans,
  useUpsellPlans,
  useEndOfSeasonOffer,
  useHardwareUpgradeOffers,
  useSkus,
  useDailySkus,
  useCancelSubscription,
  useChooseNewPlan,
  useDelayPlan,
};

export default service;
