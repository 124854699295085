import React from 'react';
import OfferType from 'renewal/domain/model/OfferType';

const earlyRenewalFaqs = [
  {
    question: 'Will this change my renewal date?',
    answer: 'No, your renewal date will remain the same but you will be charged today.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today.',
  },
  {
    question: 'When does the offer end?',
    answer: 'This offer will expire at 23:59 on 2nd December 2024.',
  },
  {
    question: 'What if I choose not to take this offer?',
    answer: 'You will be charged on the date shown on your pass, unless you decide to cancel.',
  },
];

const upgradeFaqs = [
  {
    question: 'Will this change my renewal date?',
    answer: 'By starting one of our Season passes today, your next renewal date will be a year from today. Your original pass will not be continued.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today.',
  },
  {
    question: 'When does the offer end?',
    answer: 'This offer will expire at 23:59 on 2nd December 2024.',
  },
  {
    question: 'How are our season passes different from my current pass?',
    answer: 'Our season passes are the most cost-effective options, covering you for unlimited days of carving throughout the whole 365-day window.',
  },
];

const purchaseFaqs = [
  {
    question: 'Will this change my pass status?',
    answer: 'No, your existing pass will remain as it is currently',
  },
  {
    question: 'When does the offer end?',
    answer: 'This offer will expire at 23:59 on 2nd December 2024.',
  },
];
const FAQ = ({ offerType }: { offerType: OfferType }) => (
  <div className="text-blue-900 my-24 px-12">
    <div className="text-24 font-bold">FAQs</div>
    {offerType === OfferType.HARDWARE_EARLY_RENEWAL && earlyRenewalFaqs.map((faq) => (
      <div className="mt-16">
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
    {offerType === OfferType.HARDWARE_UPGRADE_RENEWAL && upgradeFaqs.map((faq) => (
      <div className="mt-16">
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
    {offerType === OfferType.HARDWARE_ONLY && purchaseFaqs.map((faq) => (
      <div className="mt-16">
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
  </div>
);

export default FAQ;
